// Mixins

@mixin shadow() {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

@mixin shadow2() {
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

@mixin shadow3() {
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) !important;
}

@mixin scroll() {
	/* width */
	&::-webkit-scrollbar {
	  width: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
	  background: #f1f1f1; 
	}
	 
	/* Handle */
	&::-webkit-scrollbar-thumb {
	  background: #888; 
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
	  background: #555; 
	}
}

@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin placeholderCenter() {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	  text-align: center;
	}

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  text-align: center;
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
	  text-align: center;
	}
}

@mixin placeholderBold() {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	  font-family: $font-family-bold;
	}

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  font-family: $font-family-bold;
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
	  font-family: $font-family-bold;
	}
}

@mixin noSelect() {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}