// Home

#admin-users {
	padding: 30px;
	.table-container {
		max-width: 90%;
		margin: 0 auto;
	}
	.danger {
		color: $danger;
	}
	select {
		box-shadow: none !important;
	}
	.image-search-input {
		top: 42px;
	}
	.row-filter {
		margin-left: 30px;
		margin-top: 30px;
	}
	.btn-reset {
		margin: 0px;
		font-size: 12px;
		cursor: pointer;
		font-family: $font-family-bold;
		display: inline-block;
		margin-left: 50px;
	}
	.link-icon {
		font-size: 20px;
	}
	.row-total {
		margin-left: 30px;
		margin-top: 30px;
	}
	.link-icon {
		display: inline-block;
		margin-right: 10px;
		img {
			width: 30px;
			height: 30px;
			object-fit: contain;
		}
	}
	.link-danger {
		color: $danger;
	}
	.item-plan {
		font-size: 14px;
	}
}

.modal-create-user {
	.modal-header {
		background-color: $primary !important;
	}
}

#modal-create-user {
	input, select {
		background-color: $prod-gray !important;
	}
	textarea {
		background-color: $prod-gray !important;
		box-shadow: none !important;
	}
	.container-datepicker {
		box-shadow: none !important;
		margin-top: 4px;
	}
	select {
		box-shadow: none !important;;
	}
	.check-container {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		cursor: pointer;
		@include noSelect();
		.check {
			margin-right: 10px;
			border: 1px solid #e0e0e0;
			border-radius: 5px;
			background-color: #e0e0e0;
			width: 20px;
			height: 20px;
			&.active {
				background-color: $green;
				border-color: $green;
			}
		}
		p {
			margin-bottom: 0px;
			font-size: 13px;
			margin-top: 2px;
		}
	}
	.container-paper-clip {
		margin-bottom: 30px;
		button {
			width: 100%;
			border-radius: 10px !important;
			background-color: $prod-green !important;
		}
	}
}