// Select

select.form-control {
	border-radius: 3px;
	background-color: $input !important;
	outline: 0px !important;
	// box-shadow: none !important;
	&:focus {
		@include shadow();
	}
	@include shadow();
	border: 0px !important;
	font-size: 14px;
	height: 40px;
	&.white {
		background-color: $white !important;
	}
	&.green {
		background-color: $prod-green2 !important;
		color: $white !important;
	}
	&.input-gray{
		background-color: $gray !important;
	}
}