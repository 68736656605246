// Base

* {
	font-family: $font-family;
}

body {
	background-color: $prod-gray;
	overflow-x: hidden;
}

.icon-view-photo {
	margin-right: 5px;
	img {
		width: 20px;
	}
}

.container-menu {
	display: flex;
	flex-direction: row;
	padding-left: $width-menu;
	transition: .6s;
	@media (max-width: 767px) {
		padding-left: 0px !important;
	}
}

.forbidden {
	margin: 20px;
	font-size: 14px;
	text-align: center;
}

.navbar-toggler {
	border: 0px !important;
	outline: 0px !important;
	height: 54px;
}

.navbar {
	padding: 0px;
	background-color: $prod-green !important;
	@media (min-width: 768px) {
		display: none;	
	}
	.navbar-collapse {
		a {
			color: $white !important;
		}
	}
	.item-submenu {
		width: 90%;
		margin-left: 10%;
	}
	.item-submenu.active {
		a {
			&::after {
				content: '';
				background-color: $white;
				width: 8px;
				height: 8px;
				border-radius: 5px;
				margin-left: 10px;
				display: inline-block;
			}
		}				
	}
	.nav-link {
		padding: 10px;
	}
	.nav-item.active {
		background-color: $prod-green2;
	}
}
.modal.high-modal {
	.modal-dialog {
		max-width: 80% !important;
		@media (max-width: 600px) {
			max-width: 100% !important;
		}
	}
}
.nav-link {
	.badge {
		background-color: $prod-green;
		margin-left: 10px;
		border-radius: 30px;
	}
}

#left {
	.toggle-menu {
		position: fixed;
		bottom: 10px;
		left: 10px;
		z-index: 99999;
		cursor: pointer;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 10px;
		@media (max-width: 767px) {
			display: none;
		}
		i.fa {
			font-size: 28px;
			color: $white;
		}
	}
	&.closed {
		.menu {
			ul {
				margin-top: 71.5px;
			}
		}		
	}	
}

.menu {
	@media (max-width: 767px) {
		display: none;
	}
	.container-logo {
		display: flex;
		margin-bottom: 20px;
		&.closed {
			margin-bottom: 30px;
			p {
				display: none;	
			}
			img {
				position: absolute;
				right: 0px;
			}		
		}
		img {
			width: 60px;
			margin-left: 10px;
			margin-right: 10px;
			margin-top: -10px;
			height: 60px;
		}
		p {
			font-size: 12px;
			color: $white;
			margin-top: 17.5px;
			font-family: $font-family-bold;
			text-transform: uppercase;
		}
	}
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100vh;
	height: 100%;
	margin-bottom: 0px !important;
	background-color: $prod-green;
	width: $width-menu;
	transition: .6s;
	z-index: 1000;
	@include shadow2();
	.container-user {
		display: flex;
		align-items: center;
		margin-top: 50px;
		margin-bottom: 20px;
		margin-left: 15px;
		width: 80%;
		img {
			width: 50px;
			height: 50px;
			border-radius: 100px;
			margin-right: 20px;
			object-fit: cover;
		}
		h2 {
			color: $white;
			font-family: $font-family-bold;
			font-size: 16px;
			margin-top: 10px;
		}
	}
	.btn-logout {
		color: $white !important;
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: 20px;
		box-shadow: none !important;
		border: 0px !important;
		outline: 0px !important;
	}
	ul {
		padding: 0px !important;
		list-style: none;
		&.closed {
			li {
				padding-left: 75%;
			}
		}
		li {
			@include ellipsis();
			transition: .6s;
			&.item-submenu.active {
				a {
					&::after {
						content: '';
						background-color: $white;
						width: 8px;
						height: 8px;
						border-radius: 5px;
						margin-left: 10px;
						display: inline-block;
					}
				}				
			}
			span.closed {
				display: none;
			}
			.submenu {
				font-size: 12px;
				padding: 5px !important;
				padding-left: 60px !important;
			}
			&.active {
				background-color: $prod-green2;
			}
			img {
				width: 22.5px;
				margin-right: 15px;
				margin-left: 10px;
			}
			a {
				position: relative;
				display: flex;
				align-items: center;
				color: $white !important;
				text-decoration: none !important;
				padding: 10px !important;
				font-size: 12px;
				.badge {
					background-color: $prod-green;
					margin-left: 10px;
					border-radius: 30px;
				}
				i {
					position: absolute;
					right: 10px;
				}
			}
		}
	}
}

.container-router {
	width: 100%;
	height: calc(100vh - 100px);
	padding-top: $header-height;
	@media (max-width: 767px) {
		padding-top: 70px;
	}
	.container-padding-router {
		padding: 30px;
		&.container-responsive {
			@media (max-width: 767px) {
				padding-top: 84px;
			}			
		}
	}
}

.ver-todo {
	color: $prod-green;
	display: inline-block;
	cursor: pointer;
	font-family: $font-family-bold;
	margin-top: 0px !important;
}

.btn-profile {
	margin-right: 40px;
}

.fa.danger {
	color: $danger;
}

.fa.success {
	color: $green;
}

.react-viewer {
	position: relative;
	z-index: 9999;
}

.input-file-paperclip {
	display: none;
}

.container-paper-clip {
	button {
		min-width: 150px;
	}
	.fa {
		margin-right: 5px;
	}
}

.container-custom-header-datepicker {
	margin: 10px;
    display: flex;
    justify-content: center;
    button {
    	background-color: transparent !important;
    	border: 0px !important;
    }
    select {
    	margin-right: 5px;
    	margin-left: 5px;
    	border: 0px !important;
    	border-radius: 5px;
    	outline: 0px !important;
    }
}