// Input

input.form-control {
	border-radius: 3px;
	background-color: $prod-gray !important;
	outline: 0px !important;
	box-shadow: none !important;
	border: 0px !important;
	font-size: 14px;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
	font-family: $font-family;
	&.input-white {
		background-color: $white !important;
	}
	&.input-gray{
		background-color: $gray !important;
	}
}

.form-group {
	label {
		font-family: $font-family;
		font-size: 14px;
	}
	&.form-group-input {
		position: relative;
	}
	.image-search-input {
		width: 25px;
		position: absolute;
		top: 45px;
		right: 15px;
	}
}