// Datepicker

.react-datepicker-wrapper {
	display: block;
}

.container-datepicker {
	border-radius: 3px;
	background-color: $input !important;
	outline: 0px !important;
	// box-shadow: none !important;
	&:focus {
		@include shadow();
	}
	@include shadow();
	border: 0px !important;
	font-size: 14px;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	min-width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	// margin-bottom: 10px;
	padding-right: 10px;
	padding-left: 10px;
	&.white {
		background-color: $white !important;
	}
	p {
		min-width: 100%;
		margin: 10px;
		padding-left: 35px;
	}
	img {
		width: 20px;
		position: absolute;
		left: 10px;
	}
}

.label-datepicker {
	display: block;
}