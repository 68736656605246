// Title

.title-component {
	width: 100%;
	padding: 10px;
	font-size: 18px;
	margin: 0px !important;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
	position: relative;
	.title-component-right {
		position: absolute;
		right: 10px;
		display: inline-block;
		top: 8px;
	}
}

.container-title-component {
	hr {
		margin: 0px;
		margin-bottom: 20px;
	}
}