// Reports

#admin-payment-report {
	padding: 30px;
	.table-container {
		max-width: 90%;
		margin: 0 auto;
	}
	.danger {
		color: $danger;
	}
	select {
		box-shadow: none !important;
	}
	.image-search-input {
		top: 42px;
	}
	.row-filter {
		margin-left: 30px;
		margin-top: 30px;
	}
	.btn-reset {
		margin: 0px;
		font-size: 12px;
		cursor: pointer;
		font-family: $font-family-bold;
		display: inline-block;
		margin-left: 50px;
	}
	.link-icon {
		font-size: 20px;
	}
	.row-total {
		margin-left: 30px;
		margin-top: 30px;
	}
	.link-icon {
		display: inline-block;
		margin-right: 10px;
		img {
			width: 20px;
			height: 20px;
			object-fit: contain;
		}
	}
	.link-danger {
		color: $danger;
	}
	.container-datepicker {
		background-color: $white !important;
		box-shadow: none !important;
		margin-top: 2px;
	}
}

#modal-create-payment {
	input, select {
		background-color: $prod-gray !important;
		box-shadow: none !important;
	}
	.container-datepicker {
		box-shadow: none !important;
		margin-top: 4px;
	}
	.container-select-search .form-control {
		background-color: $prod-gray !important;
		box-shadow: none !important;
	}
}