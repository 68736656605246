// Table

.table-component {
	background-color: transparent;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	thead {
		border: 0px !important;
		th {
			border: 0px !important;
			color: $prod-blue1;
			text-transform: uppercase;
			font-size: 14px;
			font-family: $font-family-bold;
		}
	}
	tbody {
		border: 0px !important;
		tr {
			border: 0px !important;
		}
		td {
			border: 0px;
			font-size: 14px;
			button {
				margin-right: 5px;
			}
		}
	}
}

.table-component.table-color {
	cursor: pointer;
}

.no-table-data {
	font-size: 16px;
	border-radius: 2px;
	text-align: center;
	padding: 10px;
}