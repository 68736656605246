// Header

#header {
	.container-logo {
		display: flex;
		justify-content: center;
		align-items: center;
		h3 {
			font-size: 20px;
			font-family: $font-family-bold;
			margin-bottom: 0px;
			margin-left: 25px;
			color: $blue;
		}
	}
	.logo {
		width: 200px;
	}
	.focusable-container {
		box-shadow: none !important;
		outline: 0px !important;
		border: 0px !important;
		background-color: transparent !important;
		padding: 0px !important;
		ul {
			position: absolute;
			background-color: $white;
			min-width: 250px;
			right: 10px;
			top: 40px;
			list-style: none;
			padding: 0px;
			@include shadow();
			z-index: 999;
			border-radius: 10px;
			li {
				text-align: left;
				cursor: pointer;
				font-size: 16px;
				padding: 5px;
				padding-left: 15px;
				padding-right: 15px;
				img {
					width: 25px;
					height: 25px;
					margin-right: 15px;
				}
				&:hover {
					background-color: $prod-gray2;
				}
			}
		}
		.container-user {
			display: flex;
			cursor: pointer;
			margin-right: 15px;
			border: 2px solid $black;
			border-radius: 30px;
			justify-content: center;
			align-items: center;
			padding-top: 5px;
			padding-bottom: 5px;
			img {
				width: 30px;
				height: 30px;
				margin-left: 10px;
			}
			i.fa {
				font-size: 25px;
				margin-right: 10px;
				margin-left: 15px;
			}
			p {
				padding: 0px;
				margin: 0px;
				margin-left: 10px;
				color: $black;
				font-size: 16px;
				font-family: $font-family-bold;
			}
		}
	}
}